@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap");

body {
  margin: 0;
  padding: 0;
  font-family: "Nunito", sans-serif !important;
}

:root {
  --mainColor: #172b73;
  --resizeBg: rgb(228, 233, 237);
}

.bg-color {
  background: var(--mainColor);
}

#app {
  width: 100%;
  height: 100%;
}

.cursor-pointer {
  cursor: pointer;
}

.fs-13 {
  font-size: 12px;
}

::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

::-webkit-scrollbar-thumb {
  background: var(--resizeBg);
}

.blank::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

/*--------------------------------------------- */
/*---------------- Modal CSS ------------------ */
/*--------------------------------------------- */

.modal-header,
.offcanvas-header {
  padding: 7.5px 10px;
  background: var(--mainColor);
  color: #fff;
}

.modal-footer {
  padding: 5px 15px;
  background: var(--resizeBg);
}

.modal-header > .modal-title.h4,
.offcanvas-header > .offcanvas-title.h5 {
  font-size: 15px;
}

.modal-header .close_modal:hover,
.offcanvas-header .close_modal:hover {
  transform: rotate(180deg);
}

.modal-header .close_modal,
.offcanvas-header .close_modal {
  transition: 1s;
  color: red;
  border-radius: 50%;
  font-size: 12px;
  border: 1px solid red;
  background: #fff;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bolder;
  cursor: pointer;
}

label {
  font-size: 12px;
}

.fs-10 {
  font-size: 10px;
}

.form-control,
.form-select {
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 3px;
  transition: 0.5s;
}

.form-control:focus,
.form-select:focus,
.form-control:hover,
.form-select:hover {
  border-color: var(--mainColor);
  box-shadow: none;
}

.form-check-input:checked {
  background-color: var(--mainColor);
  border-color: var(--mainColor);
}
/*-----------------------------------------------
--------------------- Tool Bar Css --------------
-----------------------------------------------*/

.tabbars > svg {
  background: var(--resizeBg);
  width: 25px;
  height: 25px;
  padding: 3px;
  cursor: pointer;
  border-radius: 3px 3px 0 0;
  transition: 1s;
}

.tabbars > svg:hover,
.tabButtons:hover,
.active {
  background: var(--mainColor) !important;
  fill: #fff;
  color: #fff;
  border-color: var(--mainColor) !important;
}

.tablist svg {
  width: 21px;
  height: 21px;
  padding: 3px;
  cursor: pointer;
  border-radius: 50%;
  transition: 0.4s;
}

.activeMenu {
  background: var(--resizeBg);
}

.tablist svg:hover {
  background: rgb(209 217 224);
  font: #000;
}

/*-----------------------------------------------
----------------- Profile Pic Css --------------
-----------------------------------------------*/

.profile-pic {
  transition: all 0.3s ease;
  color: transparent;
  height: 100px;
  width: 100px;
}

.profile-pic img {
  object-fit: cover;
  height: 100px;
  width: 100px;
}

.profile-pic .-label {
  cursor: pointer;
  height: 100px;
  width: 100px;
}

.profile-pic:hover .-label {
  transition: background-color 0.2s ease-in-out;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10000;
}

/*-----------------------------------------------
----------------- Table Css --------------
-----------------------------------------------*/

table tr th {
  background-color: var(--resizeBg) !important;
  padding: 4px 10px !important;
  font-size: 12px;
}

table tr td {
  padding: 4px 10px !important;
}

/* table thead tr th {
  background-color: var(--icon_color) !important;
  color: var(--bg_white) !important;
  padding: 0.35em;
} */

.table > :not(caption) > * > * {
  background-color: transparent;
}

.custom-table.table th,
.custom-table.table td,
.TablePre th,
.TablePre td,
.tableFont th,
.tableFont td {
  font-size: 11px;
}

td {
  padding: 5px 10px !important;
}

table {
  margin-bottom: 0 !important;
}

.tableLibrary.custom-table th:last-child {
  width: 100% !important;
  table-layout: fixed;
}

.tableLibrary.custom-table {
  width: auto;
  max-width: max-content;
}

.custom-table {
  width: 100%;
  table-layout: fixed;
}

.custom-table.resizing {
  width: 100%;
  max-width: fit-content;
}

.custom-table th {
  resize: horizontal;
}

.custom-table th,
.custom-table td {
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  vertical-align: middle;
}

/* tbody tr:nth-child(odd) td {
  background-color: var(--header_bgColor);
} */

.tableLibrarySection {
  max-height: calc(100vh - 175px);
}

.headerFixed {
  position: sticky;
  top: 0;
}

/*-----------------------------------------------
----------------- Common Css --------------
-----------------------------------------------*/

.passwordvisiability {
  position: absolute;
  cursor: pointer;
  right: 8px;
  top: 4px;
}

.tabButtons {
  background: var(--resizeBg);
  transition: 1s;
  padding: 2px 4px;
}

.add_sponsor_field .input-group-text {
  font-size: 11px;
  background-color: var(--resizeBg);
  padding: 3px 8px 0;
  border-radius: 3px;
  border-color: var(--resizeBg);
}

.input-group {
  flex-wrap: nowrap;
}

.bg-danger-subtle {
  transition: 1s;
}

.bg-danger-subtle:hover {
  background: red !important;
  color: #fff !important;
}

button:disabled,
.form-control:disabled,
.form-select:disabled {
  background-color: #dee2e6 !important;
  cursor: no-drop;
  color: #000 !important;
  border-color: #dee2e6 !important;
}
